import clsx from "clsx";
import { default as Image } from "next/image";
import Link from "next/link";
import { Suspense } from "react";

import AspectRatioProvider from "@/components/modifiers/AspectRatioProvider";

import { ProductCard as PC } from "@/lib/centra/formatters/formatProductCards/formatProductCard";
import { Price, Subtitle } from "..";
import DepictWrapper from "./DepictWrapper";
import PCToolTip from "./PCToolTip";
import styles from "./product-card.module.css";
import ProductCardActionNoSSr from "./ProductCardActionNoSSr";
import { ProductSaleTag } from "./ProductSaleTag";

const ProductCard = ({
  product,
  cardsPerRow,
  inWishlist,
  className,
}: {
  product: PC;
  cardsPerRow?: number;
  inWishlist?: boolean;
  className?: string;
}) => {
  const images = [product?.gallery?.image, product?.gallery?.secondImage];
  const slug = product?.core?.slug ?? "#";
  const name = product?.core?.name;
  const sizes = product?.description?.size;
  const swatch = product?.description?.swatch;
  const discountPercentRaw = product?.description?.price?.[0]?.discountPercent;
  const discountPercentage = discountPercentRaw
    ? ` ${discountPercentRaw}%`
    : "";

  return (
    <DepictWrapper
      asChild
      depictListingResultId={product.depictListingResultId}
      depictSearchResultId={product.depictSearchResultId}
    >
      <div
        className={clsx(styles.card__container, className)}
        style={
          cardsPerRow
            ? {
                width: `calc(${cardsPerRow > 1 ? 100 / cardsPerRow : 100}% - ${
                  cardsPerRow > 1 ? 1 : 0
                }rem)`,
                minWidth: cardsPerRow > 1 ? "200px" : "50%",
                maxWidth: `calc(100% - 23.5vw)`,
                justifySelf: "center",
                margin: cardsPerRow > 1 ? "0" : "0 auto",
              }
            : {}
        }
      >
        <Link
          href={`/product/${slug}`}
          className={`product-card ${styles["product-card"]}`}
        >
          <AspectRatioProvider height={3} width={2}>
            {images.map((image, i) => {
              if (!image) return;
              return (
                <Image
                  key={i}
                  src={image.href}
                  alt={image.alt}
                  fill
                  loading="eager"
                  sizes={`(max-width: 1024px) 50vw, (max-width: 2560px) 20vw`}
                  className={clsx(
                    styles[`product-image`],
                    i === 0 ? styles.first : styles.second,
                  )}
                />
              );
            })}

            <ProductCardActionNoSSr sizes={sizes ?? []} product={product} />
          </AspectRatioProvider>

          <div className={styles["product-description"]}>
            {/* TODO:add toolTip here if name is too long */}
            <div
              className={clsx(styles["product-name"], "uppercase", "truncate")}
            >
              {name}
            </div>
            {!inWishlist && (
              <PCToolTip product={product}>
                <div className={styles.swatches}>
                  <>
                    <div
                      className={styles.swatch}
                      style={{ background: `#${swatch?.code}` }}
                    />
                    {product?.description?.variants &&
                      product?.description?.variants.length > 1 && (
                        <>
                          <p className={styles.plus}>+</p>
                          <p className={styles["more-swatches"]}>
                            {product?.description?.variants.length - 1}
                          </p>
                        </>
                      )}
                  </>
                </div>
              </PCToolTip>
            )}
            <Suspense>
              <Price
                prices={product?.description?.price}
                className={clsx(
                  styles["product-price"],
                  "uppercase",
                  "truncate",
                )}
              />
            </Suspense>
          </div>
          {product?.core?.isNew && (
            <div className={styles.card__tag}>
              <Subtitle className={styles["black-tag"]}>{"New"}</Subtitle>
            </div>
          )}
          {/*     {product.soldOut && !product?.comingSoon && (
          <div className={clsx(styles.card__tag, styles["black-tag"])}>
            <Subtitle>Sold out</Subtitle>
          </div>
        )} */}

          {product?.description?.price?.[0]?.productOnSale &&
            !product.soldOut && (
              <ProductSaleTag discountPercentage={discountPercentage} />
            )}
          {product?.comingSoon &&
            !product?.description?.price?.[0].productOnSale && (
              <div className={clsx(styles.card__tag, styles["black-tag"])}>
                <Subtitle>Coming Soon</Subtitle>
              </div>
            )}
        </Link>
      </div>
    </DepictWrapper>
  );
};

export default ProductCard;
