import { ProductCard } from "../centra/formatters/formatProductCards/formatProductCard";

type DepictListingIdResult =
  | undefined
  | Pick<ProductCard, "depictListingResultId" | "depictSearchResultId">;

type DepictProductIdResult = undefined | Pick<ProductCard, "depictProductId">;

export const DPC_ID_PARAM = "dpc-id";

const getDepictListingId = (product: DepictListingIdResult) => {
  if (!product) return;
  if (typeof product === "string") return product;
  return product?.depictListingResultId;
};

const getProductId = (product: DepictProductIdResult) => {
  if (!product) return;
  if (typeof product === "string") return product;
  return product?.depictProductId;
};

export const getProductCardProps = (product: DepictListingIdResult) => {
  if (!product) return {};

  const listingId = getDepictListingId(product);
  if (listingId) {
    return {
      "data-product-listing-result-id": listingId,
    };
  }

  if ("depictSearchResultId" in product) {
    return {
      "data-search-result-id": product.depictSearchResultId,
    };
  }

  return {};
};

export const getProductUri = (uri: string, _: DepictProductIdResult) => {
  return uri;
};

export const dpc = {
  getProductCardProps,
  getProductUri,
};
