import { usePathname, useRouter } from "next/navigation";
import { useState } from "react";

import { DepictOptions } from "@/lib/depict/types";
import { useBoolean } from "@/lib/hooks/useBoolean";
import useLocation from "@/lib/hooks/useLocation";
import { getDepictProducts } from "@/lib/hooks/useProductFilter/getProducts";
import { useQuery } from "@tanstack/react-query";

const useGlobalSearch = () => {
  const { value: open, toggle, setValue } = useBoolean(false);
  const [search, setSearch] = useState<string>("");
  const location = useLocation();

  /*  const debouncedSearch = useDebounce<string>(search, 0); */ // Toggled off for now, Depict says they can handle the load;)
  const options: DepictOptions = {
    query: search,
    limit: 12,
    merchant: "camillapihl",
    locale: "en",
    market: location?.location?.toLowerCase() || "no",
  };

  const { data, isFetching } = useQuery({
    queryKey: ["search", search],
    queryFn: () => getDepictProducts(options),
    enabled: !!search,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  const toggleOpen = () => {
    toggle();
    if (!open) {
      setSearch("");
    }
  };

  const router = useRouter();
  const paths = usePathname()
    .split("/")
    .filter((x) => x.length > 0)
    .splice(1);

  const navigateToPage = () => {
    const categoryPath =
      paths?.[0] === "shop" || paths?.[0] === "products"
        ? paths.length > 0
          ? `/${paths.map((path) => path).join("/")}`
          : ""
        : "";
    const uri = "/products" + categoryPath + "?search=" + search;
    toggleOpen();
    router.push(uri);
    setSearch("");
  };

  const clearSearch = () => {
    setSearch("");
  };

  const setOpen = (open: boolean) => {
    setValue(open);
    if (!open) {
      setSearch("");
    }
  };

  return {
    open,
    toggleOpen,
    search,
    isFetching,
    setSearch,
    navigateToPage,
    clearSearch,
    data,
    setOpen,
  };
};

export default useGlobalSearch;
