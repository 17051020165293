import { tempMatchPricelistId } from "./constants";

export const getDepictMarketFromPricelistId = async (
  priceListId: string | undefined,
) => {
  if (!priceListId) return "no";
  return (
    tempMatchPricelistId[priceListId as keyof typeof tempMatchPricelistId] ||
    "no"
  );
};
