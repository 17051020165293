"use client";
import * as Dialog from "@radix-ui/react-dialog";
import clsx from "clsx";

import { Button, Page, ProductCard } from "@/components/atoms";
import Close from "@/components/icons/Close";
import Search from "@/components/icons/Search";

import SkeletonPC from "@/components/atoms/ProductCard/SkeletonPC";
import { useScrollLock } from "@/lib/hooks/useScrollLock";
import getLink from "@/lib/storyblok/utils/getLink";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { PropsWithChildren, useEffect, useMemo } from "react";
import ProductGrid from "../ProductGrid";
import styles from "./global-search.module.css";
import useGlobalSearch from "./useGlobalSearch";

const EmptyState = ({ modalData }: any) => {
  const { clearSearch, toggleOpen } = useGlobalSearch();
  return (
    <div className={styles.searchResultWrapper}>
      <div className={styles.suggestions}>
        {modalData?.suggestions?.map((sugg, index) => {
          return (
            <Link
              className={styles.keyword}
              onClick={() => {
                clearSearch();
                toggleOpen();
              }}
              href={`/products?search=${sugg?.keyword?.toLowerCase()}`}
              key={index}
            >
              {sugg.keyword}
            </Link>
          );
        })}
      </div>

      {modalData?.cards && modalData?.cards.length > 0 && (
        <div
          style={{
            display: "flex",
            gap: "2rem",
            maxWidth: "100%",
            overflow: "auto",
            marginTop: "1rem",
          }}
        >
          {modalData?.cards.map((card, index) => {
            const cardLink = getLink(card?.link);
            return (
              <Link key={index} href={cardLink ?? ""}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    height: "100%",
                  }}
                >
                  <Image
                    style={{
                      cursor: "pointer",
                      objectFit: "cover",
                      display: "block",
                    }}
                    width={160}
                    height={200}
                    src={card?.image?.filename || ""}
                    alt={card?.image?.alt || ""}
                  />
                  <p>{card?.title && card?.title}</p>
                </div>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

const ClientGlobalSearch = ({
  modalData,
  children,
}: any & PropsWithChildren) => {
  const {
    open,
    toggleOpen,
    search,
    setSearch,
    navigateToPage,
    clearSearch,
    isFetching,
    data,
    setOpen,
  } = useGlobalSearch();

  useScrollLock(open);

  const plpContent = useMemo(() => {
    return data?.products?.flatMap((page) => page.products).slice(0, 12) || [];
  }, [data]);
  const pathname = usePathname();

  useEffect(() => {
    return () => {
      setOpen(false);
    };
  }, [pathname]);

  return (
    <Dialog.Root modal={false} open={open} onOpenChange={setOpen}>
      <Dialog.Trigger>{children}</Dialog.Trigger>
      <Dialog.Portal>
        {/*     <Dialog.Overlay className={styles.dialogOverlay}> */}
        <Dialog.Content asChild>
          <form
            className={clsx(styles["search-form"], styles.active)}
            onSubmit={(e) => {
              e.preventDefault();
              navigateToPage();
            }}
          >
            <Page as="div" className={styles.page}>
              <input
                type="text"
                className={clsx(
                  styles.input__element,
                  search.length > 0 ? styles.active : "",
                )}
                placeholder="Search here"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {search.length > 0 ? (
                <button
                  className={styles.searchButton}
                  onClick={navigateToPage}
                >
                  <Search />
                </button>
              ) : (
                <Dialog.Close asChild>
                  <button
                    className={styles.searchButton}
                    type="button"
                    onClick={() => {
                      clearSearch();
                      toggleOpen();
                    }}
                  >
                    <Close />
                  </button>
                </Dialog.Close>
              )}

              {search.length <= 0 && (
                <EmptyState modalData={modalData?.content} />
              )}

              {search.length > 0 && plpContent?.length === 0 && !isFetching && (
                <p>No results found. Please try another keyword</p>
              )}

              <ProductGrid>
                {isFetching
                  ? [...Array(12)].map((_, idx) => (
                      <SkeletonPC
                        className={"product-card"}
                        key={`product-skeleton-${idx}`}
                      />
                    ))
                  : search.length > 0 && plpContent
                    ? plpContent.map((product, i) => (
                        <ProductCard
                          className="product-card"
                          key={`product-${product?.core?.slug}-${i}`}
                          product={product}
                        />
                      ))
                    : null}
              </ProductGrid>
              <div className={styles.productsFooter}>
                {search.length > 1 &&
                  data?.products.flatMap((page) => page.products).length >=
                    12 && (
                    <Button color="gray">
                      View all {data?.totalCount} products
                    </Button>
                  )}
              </div>
            </Page>
          </form>
        </Dialog.Content>
        {/*    </Dialog.Overlay> */}
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ClientGlobalSearch;
