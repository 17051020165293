import env from "@/env.mjs";
import type {
  ICentraFilterUri,
  IFilterGroup,
  ISortOrderValue,
} from "@/types/centra";
import { cache as dedupe } from "react";

import { Category } from "@/lib/centraGenerics/categories/getCategories";
//@ts-ignore
import formatProductsForPlp from "@/app/products/[...category]/clientUtils/formatProductsForPlp";
import {
  formatDepictDisplayToPC,
  formatSBCategories,
} from "@/app/products/[...category]/serverUtils/getInitialPlpData";
import { fetchDepictCategory } from "@/app/products/[...category]/serverUtils/helpers";
import { cacheLife } from "@/cache-life";
import { DepictOptions } from "@/lib/depict/types";
import { getStory } from "@/lib/storyblok/storyblokSetup";
import {
  PlpCategoriesStoryblok,
  ProductListingPageStoryblok,
} from "@/types/storyblok-blok-types";
import { FetchError, ofetch } from "ofetch";
import type { CentraProduct } from "../../centra/atomicSetup";
import type { ProductCard } from "../../centra/formatters/formatProductCards/formatProductCard";
import type {
  IFormattedFilters,
  ProductListingResponse_internal,
} from "./types";

type IFilterBodyTypes =
  | boolean
  | number
  | string
  | undefined
  | string[]
  | ISortOrderValue[]
  | ICentraFilterUri;

export type GetProducts = <PC extends ProductCard, ExtraData>({
  cardFormatter,
  category,
  filters,
  productIds,
  search,
  sortOrder,
  page,
  productsPerPage,
  extraDataFetcher,
  customerToken,
  depictUUID,
}: // new
{
  cardFormatter: (product: CentraProduct, data?: ExtraData) => PC;
  category: Category[];
  filters?: IFormattedFilters;
  filterGroups?: IFilterGroup[];
  productIds?: string[] | undefined;
  search?: string;
  sortOrder?: ISortOrderValue[];
  page?: number;
  productsPerPage?: number;
  customerToken?: string;
  depictUUID?: string;
  soldOut?: string;
  apiToken?: string;
  extraDataFetcher?: (productIds: string[]) => Promise<ExtraData>;
}) => Promise<{
  products: PC[];
  filter: any[];
  token: string;
  productCount: number;
}>;

const getDepictProductsByCategory = async (
  options: DepictOptions,
  cursor?: string,
) => {
  const url = `${env.NEXT_PUBLIC_DEPICT_API_URL}/v3/listings/${options.category}/products`;

  try {
    const res = await ofetch<ProductListingResponse_internal>(url, {
      method: "POST",
      body: JSON.stringify({
        ...options,
        category: undefined,
        cursor: cursor || null,
        merchant: "camillapihl",
      }),
      next: {
        revalidate: cacheLife.FIVE_MINUTES,
      },
    });

    return res;
  } catch (e) {
    if (e instanceof FetchError) {
      console.error("Failed to fetch depict data", e.data);
    }
    return null;
  }
};

export const getDepictProductsByQuery = async (
  options: DepictOptions,
  cursor?: string,
) => {
  const url = `${env.NEXT_PUBLIC_DEPICT_API_URL}/v3/search/results`;
  const res = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...options,
    }),
    next: {
      revalidate: cacheLife.FIVE_MINUTES,
    },
  });

  if (!res.ok)
    return {
      displays: [],
    };
  const data = (await res.json()) as ProductListingResponse_internal;
  return data;
};

export const formatPlpData = async (data: any) => {
  const formattedDepictProducts = await Promise.all(
    data?.displays?.map(formatDepictDisplayToPC),
  );

  const formattedProductsForPlp = formatProductsForPlp(
    formattedDepictProducts as ProductCard[],
    [],
    /*  bloks, */
  );

  return {
    products: formattedProductsForPlp,
    totalCount: data?.n_hits,
    page: data?.page,
    nextCursor: data?.cursor,
    sorts: data?.sorts,
    filters: data?.filters,
  };
};

export const getPlpData = async (
  options: DepictOptions,
  categories?: PlpCategoriesStoryblok[] | undefined,
) => {
  const defaultMainCategories = await getStory<ProductListingPageStoryblok>({
    slugOrId: "/products/",
  });

  const [products, category] = await Promise.all([
    getDepictProducts(options),
    fetchDepictCategory(options),
  ]);

  const mainCategories =
    categories && categories.length > 0
      ? formatSBCategories(categories)
      : formatSBCategories(defaultMainCategories?.content?.categories);

  return {
    products,
    category,
    mainCategories,
  };
};

export const getDepictProducts = dedupe(
  async (options: DepictOptions, cursor?: string) => {
    if (options.category) {
      const data = await getDepictProductsByCategory(options, cursor);
      return formatPlpData(data);
    } else if (options.query) {
      const data = await getDepictProductsByQuery(options, cursor);
      return formatPlpData(data);
    }
  },
);

export type DepictProductResponse_internal = Awaited<
  ReturnType<typeof getDepictProducts>
>;
