"use client";

import * as Accordion from "@radix-ui/react-accordion";
import * as Dialog from "@radix-ui/react-dialog";
import * as NavMenu from "@radix-ui/react-navigation-menu";
import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";
import { default as Link } from "next/link";

import type { ResolvedHeaderStoryblok } from "@/components/molecules/Navbar/Navbar";
import getLink from "@/lib/storyblok/utils/getLink";
import type {
  LinkStoryblok,
  MenuStoryblok,
} from "@/types/storyblok-blok-types";

import styles from "./header-bloks.module.css";
import MenuItem from "./MenuItem";
import MenuLink from "./MenuLink";
import AccordionContent from "./Mobile/Accordion/Content";
import AccordionTrigger from "./Mobile/Accordion/Trigger";
import ChangeLocation from "./Mobile/ChangeLocation/ChangeLocation";

type HeaderBlokParams = {
  blok: ResolvedHeaderStoryblok;
};

type FormattedMenu = {
  is: "menu";
  menu: MenuStoryblok;
};
type FormattedLink = {
  is: "link";
  menu: () => JSX.Element;
};
type MenuOrLink = FormattedMenu | FormattedLink;

const RegularLink = ({ link, text }: { link: string; text: string }) => (
  <p className={styles.accordion__link}>
    <Link href={link ?? "#"}>{text}</Link>
  </p>
);

const formatMenu = (menu: MenuStoryblok): MenuOrLink => {
  const isExactlyOneMenu =
    menu.section?.length === 1 && menu.section[0].links?.length === 1;
  if (!isExactlyOneMenu)
    return {
      is: "menu",
      menu: menu,
    };
  const linkObj = menu?.section?.[0].links?.[0];
  const link = getLink(linkObj?.link);

  return {
    is: "link",
    menu: () => <RegularLink link={link ?? "#"} text={linkObj?.text ?? ""} />,
  };
};

const MobileMenu = ({ menu: menus, links }: HeaderBlokParams["blok"]) => {
  return (
    <div className={styles.menu__mobile}>
      <Dialog.Root>
        <Dialog.Trigger className={styles.menu__trigger}>
          {"MENU"}
        </Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className={styles.menu__overlay} />
          <Dialog.Content className={styles.menu__modal}>
            <div className={styles.menu__content}>
              <nav className={styles.menu__close__bar}>
                <Dialog.Close asChild>
                  <button className={styles.menu__close__icon}>{"X"}</button>
                </Dialog.Close>
              </nav>
              <div className={styles.menu__list}>
                <Accordion.Root
                  className={styles.menu__accordion}
                  type="single"
                  defaultValue="item-0"
                  collapsible
                >
                  {menus.map((menuData, key) => {
                    const formattedMenu = formatMenu(menuData);
                    if (formattedMenu.is === "link")
                      return <formattedMenu.menu key={menuData._uid} />;
                    const menu = formattedMenu.menu;
                    return (
                      <Accordion.Item
                        key={"mobile-menu--" + menu._uid}
                        value={"item-" + key}
                        className={styles["accordion-item"]}
                      >
                        <AccordionTrigger
                          className={styles["accordion-trigger"]}
                        >
                          {menu.title}
                        </AccordionTrigger>
                        <AccordionContent
                          className={styles["accordion-content"]}
                        >
                          {menu?.section &&
                            menu.section
                              .filter((s) => !!s?.showOnMobile)

                              .map((section) => (
                                <div
                                  className={styles.section__grid}
                                  key={"mobile-menu-section--" + section._uid}
                                >
                                  {section.links?.map(
                                    (sectionLink: LinkStoryblok) => {
                                      const link = getLink(sectionLink.link);
                                      return (
                                        <p
                                          className={styles.accordion__link}
                                          key={sectionLink._uid}
                                        >
                                          <Dialog.Close asChild>
                                            <Link href={link ?? "#"}>
                                              {sectionLink.text}
                                            </Link>
                                          </Dialog.Close>
                                        </p>
                                      );
                                    }
                                  )}
                                </div>
                              ))}
                        </AccordionContent>
                      </Accordion.Item>
                    );
                  })}

                  {links?.map((link) => {
                    const url = getLink(link.link);

                    /* type generation does not catch the color plugin type */
                    const typedLink = link as LinkStoryblok & {
                      color?: {
                        value: string;
                        plugin: string;
                      };
                    };
                    return (
                      <p key={link._uid} className={styles.accordion__link}>
                        <Dialog.Close asChild>
                          <Link
                            style={
                              {
                                "--link-color": typedLink.color?.value,
                              } as React.CSSProperties
                            }
                            href={url || "#"}
                          >
                            {link.text}
                          </Link>
                        </Dialog.Close>
                      </p>
                    );
                  })}

                  {/* change country */}

                  <ChangeLocation />
                </Accordion.Root>
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
};

const HeaderBloks = ({ blok, ...rest }: HeaderBlokParams) => {
  const maxMenuItems = 4;
  const menus = blok.menu;
  const links = blok.links;

  if (links && menus.length + links.length > maxMenuItems) {
    links.splice(maxMenuItems - menus.length);
  }

  return (
    <>
      <div className={styles.menu__desktop}>
        <NavMenu.Root
          className={styles.menuRoot}
          {...storyblokEditable(blok as unknown as SbBlokData)}
        >
          <NavMenu.List className={styles.menuList}>
            {menus.map((menu) => (
              <MenuItem {...menu} key={menu._uid} />
            ))}
            {links?.map((link) => <MenuLink {...link} key={link._uid} />)}
          </NavMenu.List>
          <NavMenu.Viewport className={styles.viewport} />
        </NavMenu.Root>
      </div>
      <MobileMenu {...blok} />
    </>
  );
};

export default HeaderBloks;
