"use client";
import clsx from "clsx";
import { useState } from "react";

import useSizes from "@/app/product/[product]/hooks/useSizes";
import { convertProductCardToCartItem } from "@/lib/centra/convertToCartItem/convertToCartItem";
import { useCart } from "@/lib/hooks/useSelection";
import usePriceList from "@/lib/hooks/useSelection/cart/usePriceList";

import { ProductCard } from "@/lib/centra/formatters/formatProductCards/formatProductCard";
import { useDPC } from "@/lib/depict/useDPC";
import useLocation from "@/lib/hooks/useLocation";
import {
  convertPCToGtagItem,
  eventAddToCart,
} from "@/lib/utils/Analytics/Functions";
import Button from "../Button";
import { WishlistButton } from "../Wishlist/WishlistButton";
import styles from "./product-card.module.css";

type Size = {
  name: string;
  label: string;
  stock: boolean;
  itemId: string;
  fewLeft: boolean;
};

type Props = {
  sizes: Size[];
  product: ProductCard & { size?: { itemId: string } };
};

const ProductCardAction = ({ sizes, product }: Props) => {
  const { DPC } = useDPC();
  const newSizes = useSizes(sizes);
  const [showActions, setShowActions] = useState(false);
  const prices = usePriceList(product?.description?.price);
  const { location, currency } = useLocation();
  const cart = useCart();
  const itemId = product?.id;

  const addToCart = () => {
    if (!newSizes?.selectedSize) return false;
    if (newSizes?.selectedSize?.itemId)
      cart.addItem.mutate({
        itemId: newSizes?.selectedSize?.itemId,
        cartItem: convertProductCardToCartItem(
          product,
          newSizes?.selectedSize,
          prices,
        ),
      });

    DPC?.sendAddToCartEvent({
      product_id: newSizes?.selectedSize?.itemId.split("-").pop(),
    });

    if (eventAddToCart)
      eventAddToCart({
        currency: currency || "",
        items: [
          convertPCToGtagItem(
            product,

            prices?.priceAsNumber,
          ),
        ],
        value: prices?.priceAsNumber,
      });

    cart.modal.openModal();
  };

  if (!newSizes.sizes) return null;

  const buttonDisabled =
    (!newSizes?.selectedSize?.stock ||
      !newSizes.selectedSize ||
      !newSizes?.selectedSize?.itemId) &&
    newSizes?.sizes?.length > 1;

  if (product?.comingSoon || !newSizes?.sizes?.some((size) => size?.stock)) {
    return null;
  }

  return (
    <>
      <WishlistButton itemId={itemId} />

      <div
        onClick={(e) => e.preventDefault()}
        tabIndex={-1}
        className={styles.root}
      >
        <Button
          color="white"
          rounded
          className={clsx(styles["pc-button"])}
          tabIndex={-1}
          onMouseEnter={() => setShowActions(true)}
          onMouseLeave={() => setShowActions(false)}
        >
          +
        </Button>
        <div className={clsx(styles.action, showActions && styles.show)}>
          {newSizes.sizes.length > 1 && (
            <div className={clsx(styles["actions-wrapper"])}>
              {newSizes.sizes.length > 1 &&
                newSizes?.sizes
                  .filter((size) => size.stock)
                  .map((size, i) => (
                    <button
                      key={i}
                      className={clsx(
                        styles["size-buttons"],
                        size.isSelected && styles.selected,
                      )}
                      disabled={!size.stock || size.isSelected}
                      onClick={size.selectSize}
                    >
                      <span
                        className={clsx(
                          !(!size.stock || size.isSelected) &&
                            "underline-hover",
                        )}
                      >
                        {size.label}
                      </span>
                    </button>
                  ))}
            </div>
          )}

          <button
            className={clsx(styles.apply__to__cart)}
            disabled={buttonDisabled}
            style={
              newSizes?.sizes?.length === 1 ? { paddingBottom: "0.5rem" } : {}
            }
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              addToCart();
            }}
          >
            <span className={clsx(!buttonDisabled && "underline-hover")}>
              {!buttonDisabled ? "Add to cart" : "Select Size"}
            </span>
          </button>
          {/* { !buttonDisabled && <span className={styles["wishlist-icon"]}>
            <Save size={newSizes?.selectedSize?.itemId} />
          </span>} */}
        </div>
      </div>
    </>
  );
};

export default ProductCardAction;
