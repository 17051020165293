"use client";

import type { ProductPricesWithDiscount } from "@frend-digital/centra/types/product";
import clsx from "clsx";

import useLocation from "@/lib/hooks/useLocation";
import usePriceList from "@/lib/hooks/useSelection/cart/usePriceList";

import styles from "./price.module.css";

const Price = ({
  prices,
  className,
}: {
  prices?: ProductPricesWithDiscount | ProductPricesWithDiscount[];
  className?: string;
}) => {
  const priceList = usePriceList(prices);
  const location = useLocation();

  const isDiscounted = priceList.productOnSale;

  if (isDiscounted)
    return (
      <div className={clsx(className, styles.discounted__wrapper)}>
        <span className={styles.price__before__discount}>
          <p className="sr-only">{"Price before discount."}</p>
          <span className={styles.discount__number}>
            {priceList.priceBeforeDiscountAsNumber}
          </span>
          <span> {location.currency}</span>
        </span>
        <span className={styles.price__after__discount}>
          <p className="sr-only">
            {"Price after " + priceList.discountPercent + "% discount."}
          </p>
          <span>{priceList.priceAsNumber}</span>
        </span>
      </div>
    );

  return (
    <span key={priceList?.price + priceList} className={className}>
      {typeof prices === "string" ? prices : priceList?.price}
    </span>
  );
};

export default Price;
