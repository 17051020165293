"use client";

import { useGlobalConfig } from "@/lib/global/GlobalConfiguration";
import clsx from "clsx";
import { Subtitle } from "..";
import styles from "./product-card.module.css";

export const ProductSaleTag = ({
  discountPercentage,
}: {
  discountPercentage: string;
}) => {
  const { story } = useGlobalConfig();

  return (
    <div>
      <div
        className={clsx(styles.card__tag, styles.sale__tag)}
        style={
          {
            "--tag-bg": story.saleColor?.color,
            "--tag-color": story.saleTextColor?.color,
          } as React.CSSProperties
        }
      >
        <Subtitle noBrackets>{"(Sale)" + discountPercentage + " OFF"}</Subtitle>
      </div>
    </div>
  );
};
