import { dpc } from "@/lib/depict/dpc-helpers";
import { Slot } from "@frend-digital/ui/components";
const DepictWrapper = ({
  asChild,
  children,
  depictListingResultId,
  depictSearchResultId,
}: {
  asChild?: boolean;
  children: React.ReactNode;
  depictListingResultId: undefined | string;
  depictSearchResultId: undefined | string;
}) => {
  const Component = asChild ? Slot : "div";
  return (
    <Component
      {...dpc.getProductCardProps({
        depictSearchResultId,
        depictListingResultId,
      })}
    >
      {children}
    </Component>
  );
};

export default DepictWrapper;
