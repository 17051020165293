"use client";

import { useDPC } from "@/lib/depict/useDPC";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import type { ReactNode } from "react";
import { useState } from "react";

const DepictDPCLoader = () => {
  useDPC();
  return null;
};
export default function Providers({ children }: { children: ReactNode }) {
  const [queryClient] = useState(() => new QueryClient());

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
      <DepictDPCLoader />
    </QueryClientProvider>
  );
}
