import { ProductCard } from "@/lib/centra/formatters/formatProductCards/formatProductCard";
import getLink from "@/lib/storyblok/utils/getLink";
import type { PlpCategoriesStoryblok } from "@/types/storyblok-blok-types";

import "core-js/features/object/group-by";

export interface DepictDisplay {
  variant_index: number;
  variant_displays: DepictVariantDisplay[];
  product_listing_result_id: string;
  search_result_id?: string;
}

export interface DepictVariantDisplay {
  display_id: string;
  display_item_id: string;
  transaction_product_id: string;
  image_urls: string[];
  item_group_id: string;
  main_product_id: string;
  related_display_item_ids: any;
  packshot_image_urls?: string[];
  product_id: string;
  currency: string;
  sale_price: number;
  original_price: number;
  color_name: string;
  uri: string;
  title: string;
  hex_color: string;
  color_img: string;
  specific_color_name: string;
  pr_coming_soon: string;
  pr_new: "1";
  product_video: any;
  size_name: string;
  filter_category?: string;
  in_stock: boolean;
}

function calculateDiscountPercentage(
  originalPrice: number,
  salePrice: number,
): number {
  if (originalPrice <= 0) {
    return 0;
  }
  const discount = ((originalPrice - salePrice) / originalPrice) * 100;
  return parseFloat(discount.toFixed(2));
}

export const formatDepictDisplayToPC = (listingDisplay: DepictDisplay) => {
  const rootVariant =
    listingDisplay.variant_displays[listingDisplay.variant_index];

  const isNew = rootVariant.pr_new === null ? undefined : rootVariant.pr_new;
  const comingSoon =
    rootVariant.pr_coming_soon === null
      ? undefined
      : rootVariant.pr_coming_soon;

  if (!rootVariant) {
    console.error(
      `Variant not found at index: ${listingDisplay.variant_index}`,
    );
    return;
  }

  const variantsGroupedByDisplay = Object.groupBy(
    listingDisplay.variant_displays,
    (o) => o.display_id,
  );

  function getSizes(display: DepictVariantDisplay) {
    const sizeFormat = (variant: DepictVariantDisplay) => {
      return {
        name: variant.specific_color_name,
        label: variant.size_name,
        stock: variant.in_stock,
        itemId: `${variant.display_item_id}-${variant.product_id}`,
        fewLeft: false,
      };
    };

    const sizes = variantsGroupedByDisplay[display.display_id]
      ?.map(sizeFormat)
      .filter(Boolean)
      .sort((a, b) => {
        if (a.itemId < b.itemId) return -1;
        if (a.itemId > b.itemId) return 1;
        return 0;
      });

    return sizes;
  }

  const type = rootVariant.uri.includes("denim")
    ? ("denim" as const)
    : ("standard" as const);

  const variantFormat = (display: DepictVariantDisplay) => {
    return {
      name: display.title,
      uri: display.uri,
      breadcrumbs: [],
      swatch: {
        code: display.hex_color?.trim(),
        name: display.color_name,
      },
      prices: [],
      selected: false,
    } satisfies ProductCard["description"]["variants"][number];
  };

  const baseFormat = (display: DepictVariantDisplay) => {
    return {
      type: type as string | undefined,
      core: {
        name: display.title,
        variantName: display.color_name,
        isNew,
        slug: display.uri,
      },

      gallery: {
        image:
          display.image_urls.length > 0
            ? {
                href: display.image_urls[0],
                alt: display.title,
              }
            : undefined,
        secondImage:
          display.image_urls.length > 1
            ? {
                href: display.image_urls[1],
                alt: display.title,
              }
            : undefined,
      },
      description: {
        size: getSizes(display),
        price: [
          {
            pricelistId: "",
            price: `${display.sale_price} ${display.currency}`,
            priceAsNumber: display.sale_price,
            priceBeforeDiscount: `${display.original_price} ${display.currency}`,
            priceBeforeDiscountAsNumber: display.original_price,
            discountPercent: calculateDiscountPercentage(
              display.original_price,
              display.sale_price,
            ),
            productOnSale: display.original_price !== display.sale_price,
            currency: display?.currency,
          },
        ],
        type: type as string | undefined,
        name: display.title,
        sku: display.transaction_product_id,
        swatch: {
          code: display.hex_color?.trim(),
        },
      },
      comingSoon, // TODO - add this to depict
      soldOut: false,
      id: display.display_item_id,
      sku: display.transaction_product_id,
      depictListingResultId: listingDisplay.product_listing_result_id,
      depictProductId: display.product_id,
      depictSearchResultId: listingDisplay?.search_result_id,
    };
  };

  const variants = Object.values(variantsGroupedByDisplay)
    .map((variants) => {
      // We know that from depict each group of variants is the same display
      const display = variants?.[0];
      if (!display) {
        return undefined;
      }

      return {
        ...variantFormat(display),
      };
    })
    .filter(Boolean);

  return {
    ...baseFormat(rootVariant),
    description: {
      ...baseFormat(rootVariant).description,
      variants,
      type: type as string | undefined,
    },
  };
};

export type DepictProductCard = ReturnType<typeof formatDepictDisplayToPC>;

export const formatSBCategories = (
  sbCategories: PlpCategoriesStoryblok[] | undefined,
) => {
  if (!sbCategories || sbCategories.length === 0) return [];
  return sbCategories[0].categories.map((category) => {
    const link = getLink(category.Link[0].link);
    // removing possible formatting errors in category links
    // we want to have only the relative path from the root of the shop
    // (e.g. / comingsoon instead of / products / comingsoon)
    const uriWithoutShop = link?.startsWith("shop")
      ? link.slice(5)
      : link?.startsWith("/shop")
        ? link.slice(6)
        : link;
    const uri = uriWithoutShop?.startsWith("products")
      ? uriWithoutShop.slice(9)
      : uriWithoutShop?.startsWith("products")
        ? uriWithoutShop.slice(10)
        : uriWithoutShop;
    return {
      name: category.Link[0].text,
      uri,
      accented: category.accent,
    };
  });
};
