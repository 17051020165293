"use client";

import * as Tooltip from "@radix-ui/react-tooltip";
import { type PropsWithChildren } from "react";

import styles from "./tool-tip.module.css";

const ToolTip = ({
  children,
  Content,
}: PropsWithChildren<{
  Content: React.ReactNode;
}>) => {
  return (
    <Tooltip.Provider delayDuration={200}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            className={styles.content}
            sideOffset={5}
            data-tooltip
          >
            {Content}
            <Tooltip.Arrow className={styles.arrow} />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

export default ToolTip;
