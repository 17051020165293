import { useQuery } from "@tanstack/react-query";
import { useRouter } from "next/navigation";
import { useState } from "react";

import { getSelection } from "@/lib/centra/selectionFunctions";

import { ofetch } from "ofetch";
import usePersistedStore from "../../stateManagement/persistedState/persistedStore";

async function fetchRefreshUserData(token: string) {
  await ofetch(`/api/refresh-userdata?token=${token}`);
}
export default function useSelection() {
  const setToken = usePersistedStore((state) => state.setToken);
  const [modalOpen, setModalLOpen] = useState(false);
  const router = useRouter();

  const selection = useQuery(
    ["selection"],
    ({ signal }) =>
      getSelection(usePersistedStore.getState().token, setToken, signal).then(
        (s) => {
          fetchRefreshUserData(s.token).then(() => {
            router.refresh();
          });

          return s;
        },
      ),
    {
      refetchOnMount: false,
    },
  );

  const modal = {
    open: modalOpen,
    setOpen: () => setModalLOpen(true),
    setClosed: () => setModalLOpen(false),
  };

  const items = selection.data?.selection?.items ?? [];
  const summary = selection.data?.selection?.summary;
  const priceList = selection?.data?.location?.pricelist;
  const country = selection?.data?.location?.country;
  const loggedIn = selection?.data?.loggedIn;

  const cart = {
    items: items,
    summary: summary,
    priceList: priceList,
    loggedIn: loggedIn,
    PromoCodes: selection.data?.selection?.discounts,
  };

  const selectionResponse = {
    data: selection.data,
    address: selection.data?.selection.address,
    cart,
    isLoading: selection.isLoading,
    refetch: selection.refetch,
    isFetching: selection.isFetching,
    country,
    loggedIn,
    modal,
  };

  return selectionResponse;
}

export type UseSelectionReturn = Awaited<ReturnType<typeof getSelection>>;

export type Cart = ReturnType<typeof useSelection>["cart"];
