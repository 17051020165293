"use client";

import { GlobalConfigurationStoryblok } from "@/types/storyblok-blok-types";
import { createContext, PropsWithChildren, useContext } from "react";

type ColorPicker = {
  _uid: string;
  color: string;
  plugin: string;
};

interface GlobalConfig {
  story: GlobalConfigurationStoryblok & {
    saleColor?: ColorPicker;
    saleTextColor?: ColorPicker;
  };
}

export const GlobalConfigContext = createContext<GlobalConfig>(null!);

export const GlobalConfigProvider = ({
  children,
  story,
}: PropsWithChildren<GlobalConfig>) => {
  return (
    <GlobalConfigContext.Provider value={{ story }}>
      {children}
    </GlobalConfigContext.Provider>
  );
};

export const useGlobalConfig = () => {
  const config = useContext(GlobalConfigContext);
  return config;
};
