import { SbBlokData, storyblokEditable } from "@storyblok/js";

import clsx from "clsx";

import DenimBodyUpdater from "@/app/products/[...category]/components/DenimBodyUpdater";
import type { PageStoryblok } from "@/types/storyblok-blok-types";

import Script from "next/script";
import StoryblokComponent from "../../storyblok/storyblokSetup/StoryblokComponent/StoryblokComponent";

const Page = ({ blok, preview }: { blok: PageStoryblok; preview: boolean }) => {
  if (!blok.body) return <p> Missing body in blok: Page</p>;
  return (
    <div
      className={clsx(blok?.denim && "denim", "page-blok")}
      {...storyblokEditable(blok as unknown as SbBlokData)}
    >
      <DenimBodyUpdater isDenim={!!blok?.denim} />
      {blok?.body?.map((blok) => {
        return (
          <StoryblokComponent blok={blok} key={blok._uid} preview={preview} />
        );
      })}
      {blok?.zendesk && (
        <Script
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=1d056c0e-58d9-4cac-b543-197c59d24690"
        />
      )}
    </div>
  );
};

export default Page;
