import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/camilla-pihl/src/app/products/[...category]/components/DenimBodyUpdater.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/app/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/_Link/_Link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/Accordion/Accordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/Accordion/AccordionGroup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/BlokWrap/BlokWrap.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/Button/Button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/ButtonLink/ButtonLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/InnerHTML/ClientInnerHTML.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/Input/Input.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/LocationSelector/NewLocationSelector.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/NewsletterInput/NewsletterInput.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/Price/Price.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/ProductCard/ProductCardAction.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProductSaleTag"] */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/ProductCard/ProductSaleTag.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/SearchBar/SearchBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/ToolTip/ToolTip.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/Video/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/modifiers/AspectRatioProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/molecules/Cart/Cart.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/camilla-pihl/src/components/molecules/GlobalSearch/ClientGlobalSearch.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/camilla-pihl/src/components/molecules/Navbar/SnackbarUI.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsLetterPopup"] */ "/vercel/path0/apps/camilla-pihl/src/components/molecules/NewsletterPopup/NewsletterPopup.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/molecules/Wishlist/ClientWishlist.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/camilla-pihl/src/components/tools/Device/Device.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/ArticleCategory/ArticleCategoryNavigation/Navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/ArticlesShowcase/ArticlesShowcase.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/Media/Media.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/ProductListing/Slider/ProductSlider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/statics/header/HeaderBloks.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/TagSideBySide/ClientSideBySide.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/TagSideBySide/UI.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalConfigProvider"] */ "/vercel/path0/apps/camilla-pihl/src/lib/global/GlobalConfiguration.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/storyblok/storyblokSetup/StoryblokComponent/ClientComponent/ClientComponent.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/storyblok/storyblokSetup/StoryblokComponent/ClientComponentWithFetcher/ClientComponentWithFetcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/camilla-pihl/src/lib/utils/Analytics/Analytics.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/styles/_index.css");
;
import(/* webpackMode: "eager", webpackExports: ["C","I","A","R","T","S"] */ "/vercel/path0/node_modules/.pnpm/@frend-digital+ui@0.8.5_@types+react-dom@18.2.23_@types+react@18.2.74_downshift@9.0.8_embla-c_ugzpomlji6f4vqv4qyp5ukpyle/node_modules/@frend-digital/ui/dist/es/Accordion-client-FOrhLflY.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["D","u","a"] */ "/vercel/path0/node_modules/.pnpm/@frend-digital+ui@0.8.5_@types+react-dom@18.2.23_@types+react@18.2.74_downshift@9.0.8_embla-c_ugzpomlji6f4vqv4qyp5ukpyle/node_modules/@frend-digital/ui/dist/es/context-client-C2amdg5z.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["c","d","D","b","a"] */ "/vercel/path0/node_modules/.pnpm/@frend-digital+ui@0.8.5_@types+react-dom@18.2.23_@types+react@18.2.74_downshift@9.0.8_embla-c_ugzpomlji6f4vqv4qyp5ukpyle/node_modules/@frend-digital/ui/dist/es/Dialog-client-BmjgRNze.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["b","c","d","e","S","f","a","g","h","i"] */ "/vercel/path0/node_modules/.pnpm/@frend-digital+ui@0.8.5_@types+react-dom@18.2.23_@types+react@18.2.74_downshift@9.0.8_embla-c_ugzpomlji6f4vqv4qyp5ukpyle/node_modules/@frend-digital/ui/dist/es/Select-client-CWGbw5v5.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/@storyblok+react@2.4.8_react-dom@18.2.0_react@18.2.0/node_modules/@storyblok/react/dist/bridge-loader.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/@storyblok+react@2.4.8_react-dom@18.2.0_react@18.2.0/node_modules/@storyblok/react/dist/story.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.20_@babel+core@7.24.0_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.20_@babel+core@7.24.0_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.20_@babel+core@7.24.0_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.20_@babel+core@7.24.0_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.20_@babel+core@7.24.0_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.20_@babel+core@7.24.0_react-dom@18.2.0_react@18.2.0/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./NeueHaasGrotDisp-66MediumItalic-Web.woff2\",\"weight\":\"600\",\"style\":\"italic\"},{\"path\":\"./NeueHaasGrotDisp-65Medium-Web.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"./NeueHaasGrotDisp-56Italic-Web.woff2\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"./NeueHaasGrotDisp-55Roman-Web.woff2\",\"weight\":\"500\",\"style\":\"normal\"}],\"variable\":\"--font-haas-grot-display\"}],\"variableName\":\"HaasGrotDisplay\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.20_@babel+core@7.24.0_react-dom@18.2.0_react@18.2.0/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./Canela-LightItalic-Web.woff2\",\"weight\":\"300\",\"style\":\"italic\"},{\"path\":\"./Canela-Light-Web.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"./Canela-ThinItalic-Web.woff2\",\"weight\":\"100\",\"style\":\"italic\"},{\"path\":\"./Canela-Thin-Web.woff2\",\"weight\":\"100\",\"style\":\"normal\"},{\"path\":\"./Canela-ThinItalic-Web.woff2\",\"weight\":\"100\",\"style\":\"italic\"},{\"path\":\"./Canela-Thin-Web.woff2\",\"weight\":\"100\",\"style\":\"normal\"}],\"variable\":\"--font-canela\"}],\"variableName\":\"Canela\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.20_@babel+core@7.24.0_react-dom@18.2.0_react@18.2.0/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./CanelaDeck-LightItalic-Web.woff2\",\"weight\":\"300\",\"style\":\"italic\"},{\"path\":\"./CanelaDeck-Light-Web.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"./CanelaDeck-ThinItalic-Web.woff2\",\"weight\":\"100\",\"style\":\"italic\"},{\"path\":\"./CanelaDeck-Thin-Web.woff2\",\"weight\":\"100\",\"style\":\"normal\"}],\"variable\":\"--font-canela-deck\"}],\"variableName\":\"CanelaDeck\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.20_@babel+core@7.24.0_react-dom@18.2.0_react@18.2.0/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./9908cdad-7524-4206-819e-4f345a666324.woff\",\"weight\":\"400\",\"style\":\"normal\"}],\"variable\":\"--font-century-gothic\"}],\"variableName\":\"CenturyGothic\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/Breadcrumbs/breadcrumbs.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/QuantitySelector/quantity-selector.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/CartItem/cart-item.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/Heading/heading.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/ProductCard/product-card.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/Skeleton/skeleton.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/molecules/Footer/Marquee/marquee.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/molecules/Footer/footer.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/Accordion/accordion.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/Article/article.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/MediaElement/sb-media.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/MediaElement/StoryblokImage/sb-image.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/ArticleCard/article-card.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/ArticleCategory/article-category.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/ArticleGallery/article-gallery.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/Button/button.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/Body/body.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/CollectionPreview/collection-preview.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/DenimSection/denim-blok.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/FeaturedArticles/featured-articles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/RichTitle/rich-title.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/Hero/hero.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/Inset/inset.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/HeroSection/hero-section.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/MediaGallery/media-gallery.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/NewsletterSignup/newslettersignup.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/ProductListing/product-listing.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/SideBySide/side-by-side.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/TitleBlok/title-blok.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/molecules/GlobalSearch/global-search.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/molecules/Navbar/navbar.module.css");
