"use client";

import { useQuery } from "@tanstack/react-query";
import { useSelection } from "../hooks/useSelection";
import { getDepictMarketFromPricelistId } from "./helpers";

export const useDPC = () => {
  const { data: selection } = useSelection();

  const { data } = useQuery({
    queryKey: ["dpc", selection],
    queryFn: async () => {
      if (!selection) return;
      const market = await getDepictMarketFromPricelistId(
        String(selection.location?.pricelist),
      );
      const DPC = await import("@depict-ai/dpc").then((mod) => mod.DPC);

      const instance = new DPC("camillapihl", {
        market,
      });

      // Populate initial data
      instance.setSessionId(selection.token);

      return instance;
    },
    enabled: !!selection,
    staleTime: Infinity,
  });

  return { DPC: data };
};

/* export const useDepictProduct = (
  productNumber: string | undefined,
  priceListId: string,
  overrideId?: string,
) => {
  return useQuery({
    queryKey: ["depict-product", productNumber, overrideId],
    queryFn: async () => {
      if (overrideId)
        return {
          product_id: overrideId,
        };
      const data = await ofetch<PartialDepictProduct>(`/api/depict/product`, {
        method: "POST",
        body: JSON.stringify({
          productNumber,
          priceListId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      return data;
    },
    enabled: !!productNumber,
  });
}; */
