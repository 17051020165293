import AspectRatioProvider from "@/components/modifiers/AspectRatioProvider";
import clsx from "clsx";
import Skeleton from "../Skeleton";
import styles from "./product-card.module.css";
const SkeletonPC = ({ className }: { className?: string }) => {
  return (
    <div className={clsx(styles.card__container, className)}>
      <div>
        <AspectRatioProvider height={3} width={2}>
          <Skeleton height="100%" />
        </AspectRatioProvider>
        <div className={styles["product-description"]}>
          {/* TODO:add toolTip here if name is too long */}
          <div
            className={clsx(styles["product-name"], "uppercase", "truncate")}
          >
            <Skeleton height="20px" />
          </div>

          <Skeleton height="16px" />
        </div>
      </div>
    </div>
  );
};

export default SkeletonPC;
