import type { ProductPricesWithDiscount } from "@frend-digital/centra/types/product";

import config from "@/config";

import useSelection from "../useSelection";

const defaultPriceList = config.priceListId;

const emptyPrice: ProductPricesWithDiscount = {
  pricelistId: "",
  price: "",
  priceAsNumber: 0,
  priceBeforeDiscount: "",
  priceBeforeDiscountAsNumber: 0,
  discountPercent: 0,
  productOnSale: false,
};

const getPriceList = (
  prices?: ProductPricesWithDiscount | ProductPricesWithDiscount[],
  priceList: number | string = defaultPriceList,
): ProductPricesWithDiscount => {
  if (!prices) return emptyPrice;
  if (typeof priceList === "string") priceList = parseInt(priceList);
  if (!Array.isArray(prices)) {
    return prices;
  }

  if (prices.length === 1 && prices[0]) {
    return prices[0];
  }

  const foundPriceList = prices.find(
    (p) => p.pricelistId === priceList.toString(),
  );

  return foundPriceList ? foundPriceList : emptyPrice;
};

const usePriceList = (
  prices?: ProductPricesWithDiscount | ProductPricesWithDiscount[],
) => {
  const { cart } = useSelection();

  const price = getPriceList(prices, cart.priceList);

  return price;
};

export default usePriceList;
