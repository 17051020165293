import { useEffect } from "react";

/**
 * @param lock
 */
export const useScrollLock = (lock: boolean = false) => {
  useEffect(() => {
    if (!lock) {
      document.body.classList.remove("scroll-locked-alt");
      return;
    }

    document.body.classList.add("scroll-locked-alt");
    return () => {
      document.body.classList.remove("scroll-locked-alt");
    };
  }, [lock]);
};
