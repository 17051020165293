export const PAGE_SIZE = 32;

// Depict does not support pricelist ID, so we need to match it to the pricelist name
// We need to come up with something smarter in the future
export const tempMatchPricelistId = {
  "25": "fi",
  "22": "dk",
  "19": "se",
  "21": "no",
  "20": "us",
};
