import env from "@/env.mjs";
import { typeByExtension } from "@std/media-types";

export const getMimeType = (url: string) => {
  const mimeType = typeByExtension(url.split(".").pop() || "");
  return mimeType;
};

export const getStreamVideoUrl = (url: string) => {
  return `${env.NEXT_PUBLIC_VIDEO_ENDPOINT}?url=${encodeURIComponent(url)}`;
};
